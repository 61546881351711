import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Message } from "element-ui";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8020"
    : "https://api.suzhoupmr.szreacool.com";
const requestTimeout = 10000;
const contentType = "application/json;charset=UTF-8";
let loadingInstance;
/**
 * axios响应拦截器
 * @param config 请求配置
 * @param data response数据
 * @param status HTTP status
 * @param statusText HTTP status text
 * @returns {Promise<*|*>}
 */
const handleData = async ({ data, status, statusText }) => {
  if (loadingInstance) loadingInstance.close();
  // 若data.code存在，覆盖默认code
  let code = data && data["code"] ? data["code"] : status;
  switch (code) {
    case 200:
      return data;
    case 401:
      store
        .dispatch("resetAll")
        .then(() =>
          router.push({ path: "/login", replace: true }).then(() => {})
        );
      break;
  }
  // 异常处理
  // 若data.msg存在，覆盖默认提醒消息
  let errMsg;
  if (code == 401) {
    errMsg = "请先登录";
  } else {
    errMsg = `${data && data["message"] ? data["message"] : statusText}`;
  }
  Message.error(errMsg);
  return Promise.reject(data);
};

/**
 * @description axios初始化
 */
const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    "Content-Type": contentType,
  },
});

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    const token = store.state.token;
    // 规范写法 不可随意自定义
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => handleData(response),
  (error) => {
    const { response } = error;
    if (response === undefined) {
      Message.error("服务器错误，请稍后再试");
      return {};
    } else return handleData(response);
  }
);

export default instance;
